<style lang="sass" scoped>

</style>
<template lang="pug">
.bg-white.shadow-sm(style='min-height: calc(90vh); max-width: 780px')
  .alert.alert-warning.border-0.mb-0(style='font-size: 12px')
    strong.mr-2 알림
    span 상태에 대한 문자/이메일/체크리스트 템플릿을 추가하도록 개선 예정입니다.
  h4.p-4.mb-0.text-primary 고객/주문 상태관리

  form.p-4(@submit.prevent='submit()')
    strong.pb-2.d-block 상태목록
    textarea.form-control.w-50(v-model='flows' rows=10 :disabled='review_text.length > 0')

    .p-2.bg-light(v-show='review_text')
      pre {{review_text}}
      b-form-checkbox(v-model='reviewed' value='Y' unchecked-value='N') Confirm
    button.mr-2.btn.btn-primary(type='button' @click='review()' :disabled='reviewed == `Y`') Review
    button.mr-2.btn.btn-primary(type='submit' :disabled='reviewed != `Y`') Save

  hr
  form.p-4(@submit.prevent='submit_config()')
    strong.pb-2.d-block status config

    .border(v-for='row in status_config')
      strong text
      input(type='text' v-model='row.text' disabled)
      strong color
      input(type='text' v-model='row.color' disabled)
      strong memo
      textarea.form-control(v-model='row.memo' rows=5)

    button.mr-2.btn.btn-primary(type='submit') Save

</template>

<script>

import difference from 'lodash/difference'

export default {
  name: 'index',
  props: ['property_id'],
  components: {

  },
  computed: {
    session() {
      return this.$store.state.session
    },
    status_flows() {
      return this.$store.state.status_flows
    },
  },
  async mounted() {
    await this.$store.dispatch('status flows', this.property_id)

    this.flows = this.status_flows.join('\n')

    this.status_config = Object.assign({}, this.$store.state.status_config)
    // this.submit()
  },
  data() {
    return {
      flows: '',
      review_text: '',
      reviewed: false,
      status_config: {},
    }
  },
  methods: {
    async review() {
      try {
        if (this.flows.includes(',')) throw new Error('상태값에 콤마(comma)를 넣을수없습니다.')
        const flows = this.flows.trim().split('\n')

        const added = difference(flows, this.status_flows)
        const removed = difference(this.status_flows, flows)

        this.review_text = `
        Added: ${added}
        Removed: ${removed}

        Final: ${flows}
        `

        // const r = await this.$http.post(`/v1/property/${this.property_id}/orders`, {
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async submit() {
      try {
        const flows = this.flows.trim().split('\n')

        const r = await this.$http.post(`/v1/property/${this.property_id}/update_status_flow`, {
          flows_text: flows.join('\n'),
        })
        if (r.data.message != 'ok') throw new Error(r.data.message || '저장실패')

        this.$modal.show('dialog', {
          title: '알림',
          text: '저장완료',
        })
        this.review_text = ''
        this.reviewed = false

        await this.$store.dispatch('status flows', this.property_id)

        this.flows = this.status_flows.join('\n')
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async submit_config() {
      try {
        const r = await this.$http.put(`/v1/property/${this.property_id}/views/status_config`, {
          status_config: JSON.stringify(this.$store.state.status_config)
        })
        if (r.data.message != 'ok') throw new Error(r.data.message || '저장실패')

        this.$modal.show('dialog', {
          title: '알림',
          text: '저장완료',
        })
        await this.$store.dispatch('status flows', this.property_id)

        this.status_config = this.$store.state.status_config
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
  },
}
</script>
